import React from "react";
import Banner from "../components/Banner/Banner";
import SectionProduct from "../components/SectionProduct/SectionProduct";

function Products() {
  return (
    <div>
      <Banner title="Ürünlerimiz" />
      <SectionProduct />
    </div>
  );
}

export default Products;
