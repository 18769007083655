import React from "react";
import GalleryCom from "../../components/GalleryCom/GalleryCom";
import Banner from "../../components/Banner/Banner";

function SingleHitit() {
  const IMAGES = [
    {
      id: 8,
      src: process.env.PUBLIC_URL + "/img/Gallery/hitit-1.webp",
      caption: "HİTİT ASSİSTAN",
      size: "300-300",
      class: "one-third",
    },
    {
      id: 9,
      src: process.env.PUBLIC_URL + "/img/Gallery/hitit-2.webp",
      caption: "HİTİT ASSİSTAN",
      size: "300-300",
      class: "one-third",
    },
    {
      id: 10,
      src: process.env.PUBLIC_URL + "/img/Gallery/hitit-3.webp",
      caption: "HİTİT ASSİSTAN",
      size: "200-200",
      class: "one-third",
    },
    {
      id: 11,
      src: process.env.PUBLIC_URL + "/img/Gallery/hitit.webp",
      caption: "HİTİT ASSİSTAN",
      size: "200-200",
      class: "one-third",
    },
    {
      id: 12,
      src: process.env.PUBLIC_URL + "/img/Gallery/hitit-4.webp",
      caption: "HİTİT ASSİSTAN",
      size: "200-200",
      class: "one-third",
    },
    {
      id: 13,
      src: process.env.PUBLIC_URL + "/img/Gallery/hitit-5.webp",
      caption: "HİTİT ASSİSTAN",
      size: "200-200",
      class: "two-third",
    },
    {
      id: 14,
      src: process.env.PUBLIC_URL + "/img/Gallery/hitit-7.webp",
      caption: "HİTİT ASSİSTAN",
      size: "200-200",
      class: "one-third",
    },
  ];
  return (
    <div>
      <Banner title={"HİTİT"} />
      <div id="performanz-trainer">
        <div class="container">
          <div class="row animate-box fadeInUp animated-fast">
            <div class="col-md-12 col-md-offset-2 text-center performanz-heading">
              <h2>Performanz Hitit Assistant</h2>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <div class="row">
                <div
                  style={{ marginTop: "-30px" }}
                  class="col-lg-8  animate-box fadeInUp animated-fast"
                >
                  <p>
                    Hitit Assistant, özellikle altyapılar için devrim niteliği
                    taşıyan, sporda performansı arttırmak için tasarlanmış bir
                    eğitim sistemidir. Hitit Sporcunun;
                  </p>
                  <div className="my_container">
                    <ul>
                      <li>kontrol,</li>
                      <li>teknik,</li>
                      <li>tepki,</li>
                      <li>sürat,</li>
                      <li>çabukluk,</li>
                      <li>koordinasyon,</li>
                      <li>
                        çevre kontrolü <br />
                      </li>
                    </ul>
                    ve bunun gibi yeteneklerini ölçmek ve geliştirmek için
                    ülkemizde tasarlanan ve üretilen “elektronik spor
                    sistemi”dir.
                    <p></p>
                    <p>
                      Sporcuların gelişimine katkı sağladığı gibi sporcuların
                      motivasyonlarını da olumlu etkilemektedir. Puanlama
                      sistemi sayesinde gözlemlenebilir bir rekabet ortamı
                      oluşturarak sporcuların en üst performanslarına ulaşmasına
                      aracılık etmektedir. Hit/it Assistant futbol başta olmak
                      üzere top ile oynanan sporlarda pas başarısını ölçmek ve
                      geliştirmek için tasarlanmıştır.
                    </p>
                    <h2>Çünkü “Ölçtüğünü Geliştirirsin“!</h2>
                    <p>
                      Sistemin tamamı vuruşa duyarlı panellerden ve geri
                      bildirim için LED uyarı ışıklarından oluşmuştur.
                      <br />
                      HİTİT Assistant sistemi bir daire (6~12 mt) olarak
                      kullanılmak üzere tasarlanmıştır. Sistem istenildiği
                      takdirdedüz duvar olarak veya 2 katlı olarak da
                      üretilebilir. Böylece ileri bakış ve üste top vurma
                      teknikleri çalışmalara eklenmiş olur.
                    </p>
                    <p class="mt-5">
                      İstenilen çalışmaların veya egzersizlerin sisteme
                      eklenmesi mümkündür. Böylece çalışma prensiplerinizi
                      bozmadan istenilen şekilde sporcular antrenmanlara tabi
                      tutulabilir. HİTİT Assistant sporcunun;
                    </p>
                    <ul>
                      <li>pas şiddetini,</li>
                      <li>pas başarı yüzdesini,</li>
                      <li>tepki verme süresini (milisaniye olarak)</li>
                      <li>
                        anlık nabzını (uyumlu bir bt 4.0 kalp sensörü ile)
                      </li>
                      <li>
                        bitirme zamanını hesaplayarak kaydeder ve önceki
                        verileri ile veya başka sporcular ile karşılaştırma
                        imkanı sunar.
                      </li>
                    </ul>
                    Sistemin bütün tasarımı kullanıcıların teknik altyapılarına
                    göre özelleştirilebilir ve mevcut performans izleme
                    sistemlerine entegre edilebilir.
                    <br />
                    Tüm bu aktivitelere ek olarak eğitmenin kontrolünde anlık
                    müdahaleler ile interaktif çalışmalar yapılabildiği gibi,
                    eğitmenin istekleri doğrultusunda yeni oyunlar da bu sisteme
                    dahil edilebilir.
                    <p></p>
                    <p>
                      Daha fazlası için lütfen{" "}
                      <a
                        href="https://performa.nz/docs/Hitit_2020.pdf"
                        download=""
                      >
                        {" "}
                        tıklayınız.
                      </a>
                    </p>
                  </div>
                </div>
                <div class="col-lg-4  mb-5  animate-box fadeInUp animated-fast">
                  <img
                    src={process.env.PUBLIC_URL + "/img/Gallery/hitit-9.webp"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="performanz-started"
        class="performanz-bg"
        style={{
          backgroundImage: `url("${
            process.env.PUBLIC_URL + "/img/bg_img/img_bg_1.jpg"
          }")`,
        }}
      >
        <div class="overlay"></div>
        <div id="performanz-gallery">
          <div class="container">
            <div class="row">
              <div class="col-md-12 col-md-offset-2 text-center performanz-heading animate-box fadeInUp animated-fast">
                <h2 style={{ color: "#fff" }}>PERFORMANZ HİTİT ASSİSTANT</h2>
                <p>ÖLÇTÜĞÜNÜ GELİŞTİRİRSİN!</p>
              </div>
            </div>
          </div>

          <div class="container-fluid">
            <div class="row row-bottom-padded-md">
              <div class="col-md-12 image-popup">
                <GalleryCom images={IMAGES} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SingleHitit;
