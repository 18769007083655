import React from "react";
import { Link } from "react-router-dom";
import SingleProduct from "../../pages/SingleProduct";

function Product({ product }) {
  return (
    <div className="services position-relative">
      <span>
        <img
          className="img-responsive"
          src={`${process.env.PUBLIC_URL + product.img}`}
          alt="..."
        />
      </span>
      <h3>{product.title}</h3>
      <p>{product.description}</p>
      <div className="position-absolute bottom-0 end-43">
        <p>
            <Link className="btn btn-warning btn-outline btn-sm" to={'/product/'+ product.path }>
              İncele <i className="icon-arrow-right"></i>
            </Link>

        </p>
      </div>
    </div>
  );
}

export default Product;
