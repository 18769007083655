import React from "react";
import { BrowserRouter as Router, Switch, Route, Link,useLocation } from "react-router-dom";
import { useParams } from "react-router";
import Contact from "../../pages/Contact";
import Gallery from "../../pages/Gallery";
import Home from "../../pages/Home";
import Products from "../../pages/Products";
import About from "../../pages/About";

function Navbar() {
  const isActive =useLocation().pathname;
  //Onur was here!!
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <div className="container">
        <img
          style={{ width: "50px" }}
          src={"https://performa.nz/img/performanzlogo.ico"}
        />
        <div className="col-4 col-xs-4 col-md-4 col-sm-4" id="performanz-logo">
          <Link to="/">
            Performa
            <span>NZ</span>
          </Link>
        </div>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
            <li className={`nav-item ${isActive ==="/" ?"active":""}`}>
              <Link  className="nav-link" aria-current="page" to="/">
                Ana Sayfa
              </Link>
              {/* <a className="nav-link active" aria-current="page" href="#">
                Ana Sayfa
              </a> */}
            </li>
            <li className={`nav-item ${isActive ==="/products" ?"active":""}`}>
              <Link className="nav-link" to="/products">
                Ürünlerimiz
              </Link>
            </li>
            <li className={`nav-item ${isActive ==="/about" ?"active":""}`}>
              <Link className="nav-link" to="/about">
                Hakkımızda
              </Link>
            </li>
            <li className={`nav-item ${isActive ==="/gallery" ?"active":""}`}>
              <Link className="nav-link" to="/gallery">
                Galeri
              </Link>
            </li>
            <li className={`nav-item ${isActive ==="/contact" ?"active":""}`}>
              <Link className="nav-link" to="/contact">
                İletişim
              </Link>
            </li>

            {/* DROPDOWN */}
            {/* <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Dropdown
              </a>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                <li><a className="dropdown-item" href="#">Action</a></li>
                <li><a className="dropdown-item" href="#">Another action</a></li>
                <li><hr className="dropdown-divider" /></li>
                <li><a className="dropdown-item" href="#">Something else here</a></li>
              </ul>
            </li> */}
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
