import React from "react";
import GalleryCom from "../../components/GalleryCom/GalleryCom";
import Banner from "../../components/Banner/Banner";

function SingleVeriAnalizPortali() {
  const IMAGES = [
    {
      id: 22,
      src: process.env.PUBLIC_URL + "/img/Gallery/vap-2.png",
      caption: "V.A.P",
      size: "200-200",
      class: "two-third",
    },
    {
      id: 23,
      src: process.env.PUBLIC_URL + "/img/Gallery/vap-3.png",
      caption: "V.A.P",
      size: "200-200",
      class: "two-third",
    },
  ];
  return (
    <div>
      <Banner title={"VERİ ANALİZ PORTALI"} />
      <div id="performanz-trainer">
        <div class="container">
          <div class="row animate-box fadeInUp animated-fast">
            <div class="col-md-12 col-md-offset-2 text-center performanz-heading">
              <h2>Performanz Veri Analiz Portalı</h2>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <div class="row">
                <div
                  style={{ marginTop: "-30px" }}
                  class="col-lg-8  animate-box fadeInUp animated-fast"
                >
                  <br />
                  Sporculara bireysel veya grup olarak yapılacak olan test,
                  ölçüm veya egzersizlerin, anlık olarak sporcu veri HAVUZUNDA
                  TOPLANMASINI ve Excel ile, eldeki başka ölçümler veya
                  egzersizlere ait verilerin her ortamda girilmesini sağlayan
                  ayrıca bu verilerin çok yönlü değerlendirilmesine imkan veren
                  yazılımların tümüdür.
                  <br />
                  <p class="mt-5">Veri girişi:</p>
                  <div className="my_container">
                    <ul>
                      <li>Performanz Ölçüm cihazları</li>
                      <li>Excel</li>
                      <li>Uyumlu diğer elektronik ölçüm cihazları</li>
                      <li>Elle</li>
                      <li>Antrenman bilgileri</li>
                      <li>Maç bilgileri</li>
                      <li>Sakatlık, sağlık bilgileri</li>
                    </ul>
                  </div>
                  <p class="mt-5">Girilen bu veriler sayesinde;</p>
                  <div className="my_container">
                    <ul>
                      <li>Sporcu gelişimi izlenir</li>
                      <li>Sporcular arasında karşılaştırma yapılabilir</li>
                      <li>Sporcuya özel antreman planı yapılabilir</li>
                    </ul>
                    <p className="mt-3">
                      Bununla birlikte, oyuncu izleme verilerinin (Scouting)
                      girilmesi imkanı, örneğin;
                    </p>
                    <ul>
                      <li>İzlenecek sporcu için kriter belirleme</li>
                      <li>
                        İzleyecek olan yetkiliye sporcu ve izleme kriterleri
                        atama
                      </li>
                      <li>
                        Tablet/mobil yazılımları üzerinden sporcu girişi
                        yapabilme
                      </li>
                    </ul>
                    <p className="mt-3">
                      Ve bu bilgilerin yetkili kullanıcı tarafından online
                      olarak görebilme imkanı
                    </p>
                  </div>
                  <br />
                  <p class="mt-2">Faydaları:</p>
                  <div className="my_container">
                    <ul>
                      <li>Sporcu verisi depolama,</li>
                      <li>Sporcunun gelişiminin aşama aşama görebilme,</li>
                      <li>
                        Verilere göre sporcuya özel antreman programı yapabilme,
                      </li>
                      <li>
                        Sporcunun, havuzdaki sporcular arasındaki seviyesini
                        görebilme,
                      </li>
                      <li>Antrenör ve antreman kalitesini arttırma,</li>
                      <li>Yetenekli sporcuların keşfi,</li>
                      <li>Sporcu keşfinin ekonomik katkısı,</li>
                      <li>
                        Havuzdaki oyuncuların marketing değerini arttırma,
                      </li>
                      <li>
                        Yazılımı kullanan firmanın marka değerini arttırma
                      </li>
                    </ul>
                  </div>
                  <br />
                  <p></p>
                  <p>
                    Sistem özellikleri ve gereksinimler için
                    <a
                     href="https://performa.nz/docs/VeriAnalizPortali_2021.pdf"
                      download=""
                    >
                      tıklayınız.
                    </a>
                  </p>
                </div>
                <div class="col-lg-4  mb-5  animate-box fadeInUp animated-fast">
                  <img
                    style={{ maxWidth: "700px", objectFit: "cover" }}
                    src={process.env.PUBLIC_URL + "/img/Gallery/vap-1.png"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          id="performanz-started"
          class="performanz-bg"
          style={{
            backgroundImage: `url("${
              process.env.PUBLIC_URL + "/img/bg_img/img_bg_1.jpg"
            }")`,
          }}
        >
          <div class="overlay"></div>
          <div id="performanz-gallery">
            <div class="container">
              <div class="row">
                <div class="col-md-12 col-md-offset-2 text-center performanz-heading animate-box fadeInUp animated-fast">
                  <h2 style={{ color: "#fff" }}>PERFORMANZ V.A.P</h2>
                  <p>ÖLÇTÜĞÜNÜ GELİŞTİRİRSİN!</p>
                </div>
              </div>
            </div>

            <div class="container-fluid">
              <div class="row row-bottom-padded-md">
                <div class="col-md-12 image-popup">
                  <GalleryCom images={IMAGES} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SingleVeriAnalizPortali;
