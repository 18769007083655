import React from "react";

function Banner({ title }) {
  return (
    <header
      id="performanz-header"
      className="performanz-cover performanz-cover-sm"
      role="banner"
      style={{
        backgroundImage: "url('https://performa.nz/img/bg_img/img_bg_1.jpg')",
        backgroundPosition: "50% 50%",
      }}
      data-stellar-background-ratio="0.5"
    >
      <div className="overlay"></div>
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-md-offset-2 text-center">
            <div className=" d-flex justify-content-center">
              <div
                className="display-tc animate-box fadeIn animated-fast" style={{marginTop: '60px'}}
                data-animate-effect="fadeIn"
              >
                <h1>
                  Performa<span style={{ color: "#f85a16" }}>NZ</span>{" "}
                  <div>{title}</div>
                </h1>
                <h2> ÖLÇTÜĞÜNÜ GELİŞTİRİRSİN !</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Banner;
