import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ReferanceLogos from './Referance.json'

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
        />
    );
}

class Referance extends Component {
    render() {
        const settings = {
            cssEase: 'linear',
            dots: false,
            infinite: true,
            speed: 250,
            autoplay: true,
            slidesToShow: 6,
            adaptiveHeight:false,
            slidesToScroll: 1,
            nextArrow: <SamplePrevArrow />,
            prevArrow: <SamplePrevArrow />
        };
        return (
            <div id="performanz-schedule" className="performanz-bg" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/img/bg_img/img_bg_1.jpg)` }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-md-offset-2 text-center performanz-heading animate-box fadeInUp animated-fast">
                            <h2>Referanslar</h2>
                        </div>
                    </div>
                    <Slider {...settings}>
                        {
                            ReferanceLogos.map((ReferanceLogo) => {
                                return (
                                        <img key={ReferanceLogo.id} src={`${process.env.PUBLIC_URL}/img/logo/${ReferanceLogo.img}`}/>

                                )
                            })
                        }

                    </Slider>
                </div>
            </div>
        );
    }
}

export default Referance
