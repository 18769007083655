import React from "react";
import GalleryCom from "../../components/GalleryCom/GalleryCom";
import Banner from "../../components/Banner/Banner";

function SingleHititMove() {
  const IMAGES = [
    {
      id: 1,
      src: process.env.PUBLIC_URL + "/img/Gallery/move-2.webp",
      caption: "HİTİT ASSİSTAN MOVE",
      size: "300-300",
      class: "one-third",
    },
    {
      id: 2,
      src: process.env.PUBLIC_URL + "/img/Gallery/move-3.webp",
      caption: "HİTİT ASSİSTAN MOVE",
      size: "300-300",
      class: "one-third",
    },
    {
      id: 3,
      src: process.env.PUBLIC_URL + "/img/Gallery/move-4.webp",
      caption: "HİTİT ASSİSTAN MOVE",
      size: "200-200",
      class: "one-third",
    },
    {
      id: 4,
      src: process.env.PUBLIC_URL + "/img/Gallery/move-6.webp",
      caption: "HİTİT ASSİSTAN MOVE",
      size: "200-200",
      class: "one-third",
    },
    {
      id: 5,
      src: process.env.PUBLIC_URL + "/img/Gallery/move-7.webp",
      caption: "HİTİT ASSİSTAN MOVE",
      size: "200-200",
      class: "one-third",
    },
    {
      id: 6,
      src: process.env.PUBLIC_URL + "/img/Gallery/move-5.webp",
      caption: "HİTİT ASSİSTAN MOVE",
      size: "200-200",
      class: "two-third",
    },
    {
      id: 7,
      src: process.env.PUBLIC_URL + "/img/Gallery/move-6.webp",
      caption: "HİTİT ASSİSTAN MOVE",
      size: "200-200",
      class: "one-third",
    },
  ];
  return (
    <div>
      <Banner title={"HİTİT MOVE"} />
      <div id="performanz-trainer">
        <div class="container">
          <div class="row animate-box fadeInUp animated-fast">
            <div class="col-md-12 col-md-offset-2 text-center performanz-heading">
              <h2>Performanz Hitit Move</h2>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <div class="row">
                <div
                  style={{ marginTop: "-30px" }}
                  class="col-lg-8  animate-box fadeInUp animated-fast"
                >
                  <p>
                    Futbol anlık kararların hızlı bir şekilde verildiği ve maç
                    içinde hızlı ve doğru pasa dayanan bir spor dalı olduğu için
                    sporcuların tekrar antrenmanları ön plana çıkmaktadır.
                    <br />
                    Altyapılarda / Profesyonel takımlarda devrim niteliği
                    taşıyan Hit/it Assistant MOVE sporcuların görme, algılama,
                    karar verme ve harekete geçme sürecini hızlandırma, ağırlık
                    transferi ile vücut dengesi pozisyonunda pozisyon sağlama
                    adına dünyanın önde gelen takımlardaki çalışma
                    sistemlerinden esinlenerek geliştirdik. Pas / Şut
                    çalışmalarında, temel paslaşma antrenmanlarında istenilen
                    drillere göre paneller yerleştirilip kullanılabilir. <br />
                    Taşınabilir olması özelliği ile istenilen çalışmalar kolayca
                    yapılandırılabilir.
                  </p>
                  <p class="mt-5"></p>
                  <h3>Özellikler :</h3>
                  <ol type="1">
                    <li>
                      Panel sayısı : 4 - 6 - 8 adet panel ve panellerin ayakta
                      durmasını sağlayan aparatlar.
                    </li>
                    <li>
                      Çalışma sayısı : 5 adet ( tarafımızdan kulübe özel
                      antrenman çalışmaları da yazılmaktadır.)
                    </li>
                    <li>1 adet android tablet</li>
                    <li>
                      Elektronik : 4 - 6 - 8 - 10 adet panel sayısına göre
                      sensör / Led teknolojisi
                    </li>
                    <li>Her panel elektroniği şarj edilebilir özelliktedir.</li>
                    <li>Paneller taşınabilir özelliktedir.</li>
                    <li>Türkçe Kullanım Kılavuzu</li>
                    <li>Türkçe Ekranlar ve Menüler</li>
                    <li>Program Eğitimlerinin Maksimum 2 saat olması</li>
                    <li>
                      Veri analizi grafiksel veya basit ortamda
                      gerçekleştirilebilir.
                    </li>
                    <li>Sporcu kayıt ve veri toplama yetenekleri</li>
                    <li>Vucut salınımları izlenebilme</li>
                    <li>
                      Bu bilişsel ve duyusal becerileri geliştirmek istenen
                      sporcular, antrenörler, eğitmenler, fizyoterapistler, spor
                      meraklıları, rehabilitasyon hastaları veya bireyler için
                      uygulamalar
                    </li>
                    <li>Geriye doğru sporcuların verilerini tutabilme</li>
                    <li>
                      Web arabirimi sayesinde bir çok yeteneği barındırma.
                    </li>
                  </ol>
                  <p></p>
                  <p>
                    Sistem özellikleri ve gereksinimler için{" "}
                    <a
                      href="https://performa.nz/docs/Hitit_Move_2020.pdf"
                      download=""
                    >
                      {" "}
                      tıklayınız.
                    </a>
                  </p>
                </div>
                <div class="col-lg-4  mb-5  animate-box fadeInUp animated-fast">
                  <img
                    src={process.env.PUBLIC_URL + "/img/Gallery/move-1.webp"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          id="performanz-started"
          class="performanz-bg"
          style={{
            backgroundImage: `url("${
              process.env.PUBLIC_URL + "/img/bg_img/img_bg_1.jpg"
            }")`,
          }}
        >
          <div class="overlay"></div>
          <div id="performanz-gallery">
            <div class="container">
              <div class="row">
                <div class="col-md-12 col-md-offset-2 text-center performanz-heading animate-box fadeInUp animated-fast">
                  <h2 style={{ color: "#fff" }}>PERFORMANZ HİTİT MOVE</h2>
                  <p>ÖLÇTÜĞÜNÜ GELİŞTİRİRSİN!</p>
                </div>
              </div>
            </div>

            <div class="container-fluid">
              <div class="row row-bottom-padded-md">
                <div class="col-md-12 image-popup">
                  <GalleryCom images={IMAGES} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SingleHititMove;
