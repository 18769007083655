import React from 'react'


function GalleryCom({images}) {

 
    const onInit = () => {
        console.log('lightGallery has been initialized');
    };
    return (
        <div id="performanz-gallery">

            <div className=" container-fluid">
                <div className="">
                    <div class="row row-bottom-padded-md">
                        <div class="col-md-12 image-popup">
                            <ul id="performanz-portfolio-list">
                                {
                                    images.map((image) => {
                                        return (
                                            <li key={image.id} class={image.class+` animate-box fadeIn animated-fast`}  data-animate-effect="fadeIn" style={{ backgroundImage: `url("${image.src}")` }}>
                                                <div class="case-studies-summary">
                                                    <span>Performanz</span>
                                                    <h2>{image.caption}</h2>
                                                </div>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  );
}

export default GalleryCom;
