import React, { useEffect } from "react";

function SingleApp({ title, link, desc, image, last_updated, version }) {
  function onload() {
    var elem = document.getElementById("performanz-header");
    elem.classList.remove("performanz-cover");
    elem.classList.add("pt-5");
    elem.classList.add("pb-5");
  }

  useEffect(() => {
    onload();
  }, []);

  return (
    <div
      style={{ background: "#31313122", borderRadius: "15px", padding: "35px" }}
    >
      <h1>{title}</h1>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
        }}
      >
        <table style={{ maxWidth: "500px" }}>
          <tr>
            <th style={{ fontSize: "20px" }}>Son güncelleme: </th>
            <th style={{ marginTop: "3px", marginLeft: "8px" }}>
              {last_updated}
            </th>
          </tr>
          <tr>
            <th style={{ fontSize: "20px" }}>Versiyon: </th>
            <th style={{ marginTop: "3px", marginLeft: "8px" }}>{version}</th>
          </tr>
        </table>
        <img src={image} style={{ maxWidth: "100px" }}></img>
      </div>

      <p>{desc}</p>

      <div className="d-flex justify-content-center">
        <button style={{ minWidth: "250px", color: "white" }} className="btn btn-warning">
          <a
            href={link}
            style={{ textDecoration: "none", color: "white!important" }}
          >
            İNDİR
          </a>
        </button>
      </div>

      {/* <div style={{ display: "flex", flexDirection: "row" }}>
        <p style={{ fontSize: "20px" }}>Son güncelleme: </p>
        <p>{last_updated}</p>
      </div>

      <div style={{ display: "flex", flexDirection: "row" }}>
        <p style={{ fontSize: "20px" }}>Versiyon: </p>
        <p style={{ marginTop: "3px", marginLeft: "8px" }}>{version}</p>
      </div> */}
    </div>
  );
}

export default SingleApp;
